import * as React from "react";
import FixedNavBar from "../../components/FixedNavbar";
import Footer from "../../components/Footer";
export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <>
        <FixedNavBar />
        <Footer />
      </>
    );
  }
}
